/* You can add global styles to this file, and also import other style files */

/* @import "@angular/material/prebuilt-themes/indigo-pink.css"; */

@font-face {
    font-family: 'opensanslight';
    src: url('assets/fonts/OpenSans-Light.ttf') format('truetype');
    font-display: auto;
}

@font-face {
    font-family: 'opensansregular';
    src: url('assets/fonts/OpenSans-Regular.ttf') format('truetype');
    font-display: auto;
}

@font-face {
    font-family: 'opensansbold';
    src: url('assets/fonts/OpenSans-Bold.ttf') format('truetype');
    font-display: auto;
}

@font-face {
    font-family: 'oswaldregular';
    src: url('assets/fonts/Oswald[wght].ttf') format('truetype');
    font-display: auto;
}

.opensans-light {
    font-family: opensanslight !important;
}

.opensans-regular {
    font-family: opensansregular
}

.opensans-bold {
    font-family: opensansbold
}

.oswald {
    font-family: oswaldregular
}

*:focus {
    outline: unset !important;
}

:root {
    /* font-colors */
    --j-darkergray: #646464;
    --j-darkgray: #373737;
    --j-gray: #5c5757;
    --j-lightgray: #CACACA;
    --j-lightergray: #F2F2F2;
    --j-extremelightgray: #F6F6F6;
    --j-graycolor: #979797;
    /* brand color */
    --j-brand-pink: #EA2B7B;
    --j-brand-vilolet: #BE4F9C;
    --j-brand-yellow: #F7AE31;
    --j-brand-green: #BDD438;
    --j-brand-blue: #1B75BA;
    --j-brand-lightblue: #AEDEE4;
    --j-brand-orange: #E66F32;
    --j-brand-red: #BD202E;
    /* background color */
    --j-bg-darkblue: #1B75BB;
    --j-bg-blue: #ABD2FA;
    --j-bg-lightblue: #D4E5F6;
    --j-bg-lighterblue: #E1EFFB;
    --j-bg-extremelightblue: #EEF7FF;
    --j-bg-white: #FFFFFF;
    /* alert color */
    --j-alert-success: #008538;
    --j-alert-danger: #D50000;
    --j-alert-warning: #FF9800;
    --j-alert-information: #0099CF;
    /* button color */
    --j-btn-primary: #FF5F2C;
    --j-btn-secondary: #06D6A0;
    --j-btn-second-secondary: #A369E8;
    --j-btn-disabled-primary: #ff8a65;
}


/* color of text */

.c-darkergray {
    color: var(--j-darkergray) !important;
}

.c-alert-info {
    color: var(--j-alert-information) !important
}

.c-darkgray {
    color: var(--j-darkgray);
}

.c-gray {
    color: var(--j-gray);
}

.c-lightgray {
    color: var(--j-lightgray) !important;
}

.c-lightergray {
    color: var(--j-lightergray);
}

.c-extremelightgray {
    color: var(--j-extremelightgray);
}

.c-graycolor {
    color: var(--j-graycolor);
}


/* background color */

.c-lighterblue {
    color: var(--j-bg-lighterblue);
}

.bg-blue {
    background-color: var(--j-bg-blue);
}

.bg-lighterblue {
    background-color: var(--j-bg-lighterblue);
}

.bg-extremtelightblue {
    background-color: var(--j-bg-extremelightblue);
}

.c-white {
    color: var(--j-bg-white) !important;
}

.bg-white {
    background-color: var(--j-bg-white);
}


/* Altet */

.c-alert-danger {
    color: var(--j-alert-danger)
}


/* button color */

.c-btn-primary {
    color: var(--j-btn-primary) !important;
}

.bg-btn-primary {
    background-color: var(--j-btn-primary) !important;
}

.c-btn-secondary {
    color: var(--j-btn-secondary) !important;
}

.bg-btn-secondary {
    background-color: var(--j-btn-secondary) !important;
}

.bg-btn-sec-secondary {
    background-color: var(--j-btn-second-secondary) !important;
}

.c-btn-sec-secondary {
    color: var(--j-btn-second-secondary) !important;
}

.bg-sec-secondary {
    background-color: var(--j-btn-second-secondary) !important;
}

.bg-disabled-primary {
    background-color: var(--j-btn-disabled-primary) !important;
}

.c-disabled-primary {
    color: var( --j-btn-disabled-primary) !important;
}

.bg-btn-danger {
    background-color: var(--j-alert-danger) !important;
}


/* buttons */

.lg-btn {
    font-size: 16px;
    padding: 8px 18px !important;
}

.md-btn {
    font-size: 14px;
    padding: 8px 18px !important;
}

.sm-btn {
    font-size: 12px;
}


/* cursor hand */

.clickable {
    cursor: pointer;
}


/* font sizes */

.j-d1 {
    font-size: 60px !important;
}

.j-d2 {
    font-size: 48px !important;
}

.j-h1 {
    font-size: 40px !important;
}

.j-h2 {
    font-size: 30px !important;
}

.j-h3 {
    font-size: 24px !important;
}

.subheading {
    font-size: 20px !important;
}

.body-text {
    font-size: 18px !important;
}

.body-subtext {
    font-size: 16px !important;
}

.small-text {
    font-size: 14px !important;
}

.smaller-text {
    font-size: 12px !important;
}

.caption {
    font-size: 10px !important;
}

a:hover {
    text-decoration: none !important;
}

.black-skin {
    background-color: rgba(23, 19, 22, 0.5);
}

*:focus {
    outline: none !important;
}

.center-box {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

.input-field {
    width: 100%;
    height: 34px;
    padding: 6px 11px;
    line-height: 1.42857143;
    background-color: #fff;
    background-image: none;
    border: 1px solid #1b75bb;
    border-radius: 5px;
    /* box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075); */
    font-size: 14px;
    color: var( --j-darkgray);
}

.j-primary-border {
    border: 1px solid var(--j-btn-primary) !important;
}

.j-sec-border {
    border: 1px solid var(--j-btn-secondary) !important;
}

.text-with-dot {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

body {
    font-family: opensanslight !important;
    color: var(--j-darkgray) !important;
    text-shadow: 0 0 0 #333 !important;
}

button {
    font-family: opensansregular !important
}

input.ng-touched.ng-invalid {
    border: 1px solid #d50000;
    box-shadow: 0 0 1px inset #d50000;
}

input.ng-invalid.error {
    border: 1px solid #d50000;
    box-shadow: 0 0 1px inset #d50000;
}

select.ng-touched.ng-invalid {
    border: 1px solid #d50000;
    box-shadow: 0 0 1px inset #d50000;
}

select.ng-invalid.error {
    border: 1px solid#d50000;
    box-shadow: 0 0 1px inset #d50000;
}

input[type=radio].with-font {
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    background-color: #fff;
}

input[type=radio].with-font~label::after {
    font-family: FontAwesome;
    display: block;
    content: "\f1db";
    margin-left: 30px;
    float: right;
    font-size: 22px;
    color: #dedede
}

input[type=radio].with-font:checked~label::after {
    content: "\f058";
    margin-left: 30px;
    float: right;
    font-size: 22px;
    color: #fff
}

input[type=radio].with-font {
    visibility: hidden;
}

.j-radio {
    padding: 12px 15px 9px 20px !important;
    border: 2px solid #f0f0f0;
    border-radius: 30px;
    line-height: normal;
    cursor: pointer;
    background-color: #fff;
}

.j-radio-disable {
    padding: 12px 15px 9px 20px !important;
    border: 2px solid #f0f0f0;
    border-radius: 30px;
    line-height: normal;
    cursor: pointer;
}

.j-rounded {
    border-radius: 30px !important;
}

.j-primary-border {
    border: 1px solid var(--j-btn-primary) !important;
}

.j-sec-border {
    border: 1px solid var(--j-btn-secondary) !important;
}

.text-with-dot {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.pref-round-chip {
    border: 1px solid var(--j-btn-primary);
    padding: 8px 13px;
    border-radius: 30px;
    text-align: center;
    margin-right: 10px;
}

.b-0 {
    bottom: 0;
}

.create-profile-navigation-button {
    position: fixed !important;
    bottom: 0px !important;
    z-index: 12 !important;
}

ul.jodi-create-profile-cboxtags {
    list-style: none;
    padding-left: 0px;
}

ul.jodi-create-profile-cboxtags li {
    display: inline;
}

ul.jodi-create-profile-cboxtags li label {
    display: inline-block;
    background-color: #fff;
    border: 1px solid #dedede;
    color: #373737;
    /* border-radius: 25px; */
    border-radius: 10px;
    white-space: nowrap;
    margin: 3px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all 0.2s;
}

ul.jodi-create-profile-cboxtags li label {
    padding: 7px 18px;
    cursor: pointer;
    font-size: 16px;
}

ul.jodi-create-profile-cboxtags li label+span::after {
    display: inline-block;
    /* font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased; */
    /* font-family: "Font Awesome 5 Free"; */
    font-family: FontAwesome;
    /* font-weight: 900; */
    font-size: 12px;
    padding: 2px 6px 2px 2px;
    content: '\f00c';
    color: #cacaca;
    transition: transform 0.3s ease-in-out;
    position: absolute;
    top: -2px;
    right: -4px;
}

ul.jodi-create-profile-cboxtags li input[type="checkbox"]+label+span {
    width: 18px;
    height: 18px;
    position: relative;
    right: -2px;
    background-color: #ffffff;
    border: 1px solid #cacaca;
    top: -12px;
    right: 16px;
}

ul.jodi-create-profile-cboxtags li input[type="checkbox"]:checked+label+span {
    border: 1px solid #ff5f2c;
}

ul.jodi-create-profile-cboxtags li input[type="checkbox"]:checked+label+span::after {
    content: '\f00c';
    font-family: FontAwesome;
    /* transform: rotate(-360deg); */
    transition: transform 0.3s ease-in-out;
    color: #ff5f2c;
}

ul.jodi-create-profile-cboxtags li input[type="checkbox"]:checked+label {
    border: 1px solid #ff5f2c;
    background-color: #ff5f2c;
    color: #fafafa;
    transition: all 0.2s;
}


/* ul.jodi-create-profile-cboxtags li input[type="checkbox"] {
    display: absolute;
  }
   */

ul.jodi-create-profile-cboxtags li input[type="checkbox"] {
    position: absolute;
    opacity: 0;
}

body {
    font-family: opensanslight !important;
    color: var(--j-darkgray) !important;
    text-shadow: 0 0 0 #333 !important;
}

.toolTipBgColor {
    background-color: #b71c1c;
}

.toolTipInfoBgColor {
    background-color: #373737;
}

.mat-tab-label-active {
    background-color: #06d6a0 !important;
    color: #fff !important;
}

.mat-tab-label {
    color: #06d6a0;
    border: 1px solid #06d6a0
}

.mat-ink-bar {
    display: none;
}

.mat-tab-label-content {
    font-family: opensansregular;
    font-size: 14px;
}

.mat-tab-labels>.mat-tab-label:first-child {
    display: none;
}

.box-shadow-section {
    box-shadow: 0 0 6px 0 #a8a8a8;
}

.mat-slide-toggle.mat-checked.mat-disabled .mat-slide-toggle-thumb::before {
    /* background-color: white; */
    content: "\2713";
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: 12px;
    left: 35%;
    top: 56%;
    height: 24%;
    color: #ff5f2c;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: white;
    
}

.mat-slide-toggle .mat-slide-toggle-thumb::before {
    /* background-color: white; */
    content: "\2715";
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: 11px;
    left: 30%;
    top: 50%;
    height: 36%;
    color: #CACACA;
}

.dialog-bg-trans {
    background-color: transparent !important;
    opacity: 0.8 !important;
    background-color: #000000 !important;
}


.ph-item {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    margin-bottom: 30px;
    background-color: #fff;
    /* border: 1px solid #e6e6e6; */
    border-radius: 2px;
}

.ph-item,
.ph-item *,
.ph-item ::after,
.ph-item ::before {
    box-sizing: border-box;
}

.ph-item::before {
    content: " ";
    position: absolute;
    top: 0;
    right: -35%;
    bottom: 0;
    left: -35%;
    z-index: 1;
    /* width: 500%; */
    /* margin-left: -250%; */
    -webkit-animation: phAnimation 1.5s linear infinite;
    animation: phAnimation 1.5s linear infinite;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 46%, rgba(255, 255, 255, 0.35) 50%, rgba(255, 255, 255, 0) 54%) 50% 50%;
}

.ph-item>* {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
    padding-right: 15px;
    padding-left: 15px;
}

.custom-snackbar {
    background-color: #BD202E !important; /* Red background for danger */
    color: #FFFFFF !important; /* White text color */
    font-weight: bold;
  }

  .custom-snackbar .mat-simple-snackbar-action button {
    color:#FFFFFF !important; /* White text color */
    ;
  }

@-webkit-keyframes phAnimation {
    0% {
        transform: translate3d(-30%, 0, 0);
    }
    100% {
        transform: translate3d(30%, 0, 0);
    }
}

@keyframes phAnimation {
    0% {
        transform: translate3d(-30%, 0, 0);
    }
    100% {
        transform: translate3d(30%, 0, 0);
    }
}